@import url('https://fonts.googleapis.com/css?family=Comfortaa:700|Source+Sans+Pro:200');

$primary: #8e182c; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

nav {
	z-index: 1000;
}

h1,h2,h3 {
	font-family: 'Comfortaa', cursive;
}

.navbar .navbar-nav {

    & > li > a {
		text-align: center;
		font-size: 1em;
		padding: 1.5em 0.7em;

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}

.nav.navbar-nav {
	margin-top: 10px;
}
/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		@include btn2;
		display: block;
		width: 75%;
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}
/** END LOGIN FORM **/


footer {
	padding: 50px 15px 20px 15px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

.logo {
	max-width: 250px;
	margin: 0.5em;
	@media (max-width: 400px) {
		max-width: 150px;
		margin-top: 10px;
	}
}

.bg {
	background: #0db23f;
}
.noPad {
	padding: 0;
	margin: 0;
}
.pad {
	padding: 40px 100px;
	@media(max-width: 767px) {
		padding: 40px;
	}
}

/** PADDING FOR MOBILE **/
@media (max-width: 991px) {
	
	.padTop {
		padding-top: 40px;
	}
	.padBtm {
		padding-bottom: 40px;
	}
}


.bg2 {
	background: url(../img/banner2.jpg) no-repeat;
	height: 800px;
	background-size: cover;
	padding: 275px 0;
	p, h1 {
		color: white;
	}
	@media (max-width: 900px) {
		background-position: 50% 50%;
		height: auto;
		padding: 50px 0;
	}
}
.bgImg1 {
	background: url(../img/img1.jpg) no-repeat;
	background-size: cover;
	height: 450px;


}
.bgImg2 {
	background: url(../img/img2.jpg) no-repeat;
	background-size: cover;
	height: 450px;
	@media (max-width: 991px) {
		display: none;
	}
	@media (max-width: 650px) {
		display: none;
	}
}

.flex {
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 991px) {
		display: block;
	}

}

.banner {
	background: url(../img/banner.jpg) no-repeat center;
	background-size: cover;
	padding: 24em 0em;
	@media (max-width: 767px) {
		padding: 6em 0em;
	}

	h1,h2,h3,h4 {
		color: $wht;
		text-shadow: 0px 0px 5px $primary;
	}
	h1 { 
		text-transform: uppercase;
		font-size: 4em;
	}

	h2,h3,h4 {
		font-style: italic;
	}
}


p {
	font-size: 24px;
	font-family: 'Source Sans Pro', sans-serif;
}
a.btn.btn-large {
	background: $primary;
	color: white;
	padding: 20px 30px;
	display: inline-block;
	font-size: 20px;
	font-family: 'Source Sans Pro', sans-serif;
	margin: 20px auto;
	@media (max-width: 767px) {
		padding: 10px 20px;
		font-size: 16px;
	}
}

.hideLg {
	display: none;
	@media (max-width: 991px) {
		display: block;
	}
}

.col-xs-6 {
	@media (max-width: 400px) {
		width: 100%;
	}
}

.top-pad li, .top-pad {
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 16px;
}


.primaryBg {
	padding: 4em 0em;
	background-color: $primary;
	color: $wht;
}

.row {
	// border-top: 2px solid $primary;
	// border-bottom: 2px solid $primary;
	@media (max-width: 767px) {
		border: none;
	}
}

.container-fluid {
	border-top: 2px solid $primary;
	border-bottom: 2px solid $primary;
}

hr {
	width: 150px;
	border-color: $primary;
}

h1,h2 {
	text-align: center;
	margin-bottom: 1em;
}

li {
	font-size: 1.5em;

	@media (max-width: 767px) {
		font-size: 1.25em;
	}
}

@media (max-width: 767px) {
.logo {
	max-width: 150px;
	padding-left: 0.5em;
}

.navbar-toggle {
	margin-top: 15px;
}

	.nav.navbar-nav {
		margin: 15px 0px 0px;
	}

  .navbar .navbar-nav > li > a {
    height: 40px;
    display: block;
	text-align: center;
	font-size: 0.8em;
	padding: 0px;
  }
}

#list {
  text-align: center;
  
    ul {
      text-align: left;
      display: inline-block;
    }
}